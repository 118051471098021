<template>
  <v-app>
    <div>
      <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{
          'alert-error': alertType == 'error',
          'alert-success': alertType == 'success'
        }"
      >
        {{ alertMessage }}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto">
         <div class="mb-8 mt-8 text-center">
          <h1>Create User</h1>
        </div>

        <v-form ref="users" v-model="valid">
          <v-row>
            <v-col class="mb-6" >

              <v-btn class="btn-style justify-start text-style" @click.prevent="perSearch = true"  width="100%" height="38" outlined >  <v-icon left>mdi-magnify</v-icon> {{formData.displayName == "" ? 'Name' : formData.displayName}}</v-btn>
            </v-col>
            <v-col >
              <TextField v-model="formData.name" :label="'Email'" />
            </v-col>

              <v-col  dense>

              <v-select
              v-model="formData.roles"
              :items="Roles"
              outlined
              label="Roles"
              dense
              item-text="name"
              item-value="id"
              return-object
              multiple
              >

              </v-select>
            </v-col>
             <v-col cols="12" class="d-flex justify-end">

                      <Button
                      :label="'Submit'"
                      :btnType="'Submit'"
                      @onClick="dialog = true"
                      :disabled="!valid"
                      :isLoading="isLoading"
                      class="mr-4"
                    />

                    <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />

                  </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
      <!-- Personnel Search Modal -->
     <Dialog ref="personnelSearch" :title="'Personnel Search'" :width="600">
    <v-row>
      <v-col cols="12">
         <v-text-field
            v-model="search"
            outlined
            label="Search"
            @blur="personnelSearchFunction"
            dense
          />
      </v-col>
      <v-col v-if="loadingPersonnelData" cols="12">
          <div class="text-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
        </v-col>
      <v-col v-else cols="12">
        <v-data-table
          dense
          @click:row="selectPersonnel($event)"
          v-model="personnel"
          item-key="id"
          :headers="searchHeaders"
          :items="personnelSearch"
          class="elevation-1 row-pointer"
          :hide-default-footer="true"
        ></v-data-table>
      </v-col>
      </v-row>
    <template v-slot:footer>
      <Button :label="'Close'" :btnType="'Cancel'" @onClick="closePersonnelSearchModal" />
    </template>

  </Dialog>
   <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isLoading" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>
   <PersonnelSearch :dialog="perSearch" @selectPer="selectPersonnel" :type="'employee'"  @close="perSearch = false"/>
  </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import Dialog from '@/components/ui/Dialog.vue'
import { employeeService, userService, roleService } from '@/services'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
// import { stateService, holidayService } from '@/services'
export default {
  components: {
    TextField,
    Button,
    Dialog,
    ConfirmationDialog,
    PersonnelSearch
  },
  data () {
    return {

      alertMessage: '',
      alert: false,
      dialog: false,
      perSearch: false,
      alertType: '',
      valid: false,
      isSaving: false,
      isLoading: false,
      personnel: [],
      personnelSearch: [],
      loadingPersonnelData: false,
      formData: {
        RoleId: '',
        displayName: '',
        name: '',
        status: 0,
        roles: null
      },
      Roles: [],
      searchHeaders: [
        {
          text: 'FullName',
          value: 'name'
        },
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'email',
          value: 'email'
        }
      ],
      search: ''
    }
  },

  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    openPersonnelSearchModal () {
      this.$refs.personnelSearch.openModal()
    },
    selectPersonnel (item) {
      this.formData.displayName = item.name
      this.formData.name = item.emailAddress
      this.search = ''
      this.closePersonnelSearchModal()
    },
    personnelSearchFunction () {
      this.personnel = []
      if (this.search === '') {
        this.personnelSearch = []
        return
      }
      this.loadingPersonnelData = true
      employeeService.searchEmployees(this.search).then(result => {
        this.personnelSearch = result.data
      }).catch(() => {}).finally(() => {
        this.loadingPersonnelData = false
      })
    },
    closePersonnelSearchModal () {
      this.personnelSearch = []
      this.$refs.personnelSearch.closeModal()
    },
    onSave () {
      this.isLoading = true
      const userRoles = []
      this.formData.roles.forEach(rol => userRoles.push({ RoleId: rol.id, RoleName: rol.name }))
      let data = {
        'DisplayName': this.formData.displayName,
        'Name': this.formData.name,
        'UserRoles': userRoles
      }
      userService.createNewUser(data).then((_) => {
        this.showAlertMessage('User created successfully', 'success')
      }).catch(error => {
        console.log(error)
        this.showAlertMessage('User creation failed', 'error')
      }).finally(() => {
        this.isLoading = false
        this.dialog = false
        this.onReset()
      })
    },
    onReset () {
      this.$refs.users.reset()
      this.personnel = []
    }
  },
  mounted () {
  //   stateService.getAllStates().then(result => {
  //     this.states = result.data.items
  //   }).catch(error => {
  //     console.log(error)
  //   })
    roleService.getAllRoles().then(result => {
      // const filterRoles = result.data.items.filter(role => role.name !== 'LCE')
      this.Roles = result.data.items
    }).catch(error => {
      console.log(error)
    })
  }
}
</script>
<style scoped>
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.v-input--selection-controls{
  margin: 0;
  padding: 0;
}
</style>
